<script setup lang="ts">
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';

defineOptions({ name: 'CheckoutLayout' });

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
</script>

<template>
    <div class="flex flex-col min-h-dvh">
        <NuxtLoadingIndicator :color="false" />
        <LayoutNotifications />
        <LayoutCheckoutHeader />

        <main class="container flex-1">
            <LayoutLegacyBrowserWarning />
            <slot />
        </main>

        <LayoutCheckoutFooter />

        <SharedOffcanvasContent
            id="layout-login-offcanvas"
            :offcanvas="offcanvas"
        />
    </div>
</template>
